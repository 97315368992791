
import { hexToPlainRgb } from "@/helpers/cssColorRgb";
import "@/styles/app.scss";
import { CssBaseline, GlobalStyles, ThemeProvider, createTheme } from '@mui/material';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { parseCookies, setCookie } from 'nookies';
import React from 'react';
import { getDesignTokens } from "../theme/theme";

export const ColorModeContext = React.createContext({
    toggleColorMode: () => { }
});

// Use the <Provider> to improve performance and allow components that call
// `useSession()` anywhere in your application to access the `session` object.
export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    const cookies = parseCookies();
    const [mode, setMode] = React.useState<'light' | 'dark'>((cookies.theme == 'dark' || cookies.theme == 'light') ? cookies.theme : 'light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    let mode: 'light' | 'dark' = prevMode === 'light' ? 'dark' : 'light';
                    setCookie(null, 'theme', mode, { maxAge: 365 * 24 * 60 * 60, path: '/' });
                    return mode;
                });
            },
        }),
        [],
    );

    const setGlobalStyles = (theme: any) => (
        <GlobalStyles
            styles={{
                ':root': {
                    '--color-input-text-up': hexToPlainRgb(theme.palette.input.text.up),
                    '--color-input-text-over': hexToPlainRgb(theme.palette.input.text.over),
                    '--color-input-text-focus': hexToPlainRgb(theme.palette.input.text.focus),
                    '--color-input-border-up': hexToPlainRgb(theme.palette.input.border.up),
                    '--color-input-border-over': hexToPlainRgb(theme.palette.input.border.over),
                    '--color-input-border-focus': hexToPlainRgb(theme.palette.input.border.focus),
                    '--color-input-background-up': hexToPlainRgb(theme.palette.input.background.up),
                    '--color-input-background-over': hexToPlainRgb(theme.palette.input.background.over),
                    '--color-input-background-focus': hexToPlainRgb(theme.palette.input.background.focus),
                    '--color-primary': hexToPlainRgb(theme.palette.primary.main),
                    '--color-primary-contrast': hexToPlainRgb(theme.palette.primary.contrastText),
                    '--color-secondary': hexToPlainRgb(theme.palette.secondary.main),
                    '--color-success': hexToPlainRgb(theme.palette.success.main),
                    '--color-error': hexToPlainRgb(theme.palette.error.main),
                    '--color-info': hexToPlainRgb(theme.palette.info.main),
                    '--color-background-default': hexToPlainRgb(theme.palette.background.default),
                    '--color-background-paper': hexToPlainRgb(theme.palette.background.paper),
                    '--color-skill-main': hexToPlainRgb(theme.palette.skill_state.main),
                    '--color-skill-progress': hexToPlainRgb(theme.palette.skill_state.progress),
                },
            }}
        />
    )


    // Update the theme only if the mode changes
    // let mode = 'dark';
    // const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    const theme = React.useMemo(() => createTheme(getDesignTokens("light")), ["light"]);

    return (
        <SessionProvider session={session}>
            <style jsx global>{`
            body {
              background: ${(theme.palette.mode == 'dark') ? "#000000" : "#e9e9e9"};
            }
            `}</style>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="MobileOptimized" content="320" />
            </Head>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline>
                        {setGlobalStyles(theme)}
                    </CssBaseline>
                    <Component {...pageProps} />
                </ThemeProvider>
            </ColorModeContext.Provider>
        </SessionProvider>
    )
}
