// import { faSquare, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaletteMode, ThemeOptions } from '@mui/material';

// Create a theme instance.
export function getDesignTokens(mode: PaletteMode): ThemeOptions {

    // const icon = (<FontAwesomIcon/>);

    return {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1700,
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    "*": {
                        margin: 0,
                        padding: 0
                    },
                    "html, body, #root": {
                        height: "100%"
                    },
                    ul: {
                        listStyle: "none"
                    }
                }
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        maxWidth: 1300
                    }
                }
            },

            MuiBreadcrumbs: {
                styleOverrides: {
                    li: {
                        marginRight: 16
                    },
                    separator: {
                        marginLeft: 0,
                        marginRight: 16
                    }
                }
            },
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        animationDuration: "2s",
                        '& .MuiCircularProgress-circle': {
                            animationDuration: "2s"
                        }
                    }
                }
            },
            MuiBackdrop: {
                defaultProps: {
                    sx: { background: "rgba(0,0,0,.75)" }
                }
            },
            MuiDialog: {
                defaultProps: {
                    transitionDuration: 400
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "0 24px 24px 24px"
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 0
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        cursor: "pointer",
                        textUnderlineOffset: "2px"
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        borderRadius: '2px',
                        fontWeight: "normal",
                        textTransform: "none",
                    }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '50%',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: "normal",
                        textTransform: 'none',
                    }
                },
                defaultProps: {
                    disableElevation: true,
                    variant: "outlined"
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: "small",
                    variant: "outlined",
                    inputProps: { "data-form-type": "other" },
                    type: "other"
                }
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        padding: '2px 10px 2px 10px'
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginLeft: 0
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        '&.table-default': {
                            border: '1px solid rgb(var(--color-background-default))'
                        }
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '.table-default &': {
                            borderBottom: '1px solid rgb(var(--color-background-default))'
                        },
                        '.table-default &:nth-of-type(even)': {
                            backgroundColor: 'rgba(var(--color-background-default), 0.3)'
                        },
                        '&:hover .table-cell-on-hover': {
                            visibility: "visible"
                        },
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        border: 0,
                        padding: '5px',
                        '& .table-cell-on-hover': {
                            visibility: 'hidden'
                        },
                        "&.action": {
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: 'rgba(var(--color-background-default), 0.2)'
                            }
                        },
                        '.table-buffed &': {
                            padding: '16px'
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: `rgb(var(--color-input-text-up))`,
                        "&.Mui-focused": {
                            color: `rgb(var(--color-input-text-up))`
                        },
                        "&.Mui-error": {
                            color: `rgb(var(--color-error))`
                        }
                    },
                }
            },

            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        color: `rgb(var(--color-input-text-up))`,

                        // Hover
                        '&:hover': {
                            color: `rgb(var(--color-input-text-over))`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            background: `rgb(var(--color-input-bg-over))`,
                            borderColor: `rgb(var(--color-input-border-over))`,
                        },

                        '&.Mui-focused': {
                            color: `rgb(var(--color-input-text-focus))`,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px',
                            background: `rgb(var(--color-input-bg-focus))`,
                            borderColor: `rgb(var(--color-input-border-focus))`,
                        },
                        '&.Mui-focused &-notchedOutline': {
                            borderWidth: '1px',
                        },

                        // placeholder
                        '& input::placeholder': {
                            opacity: .4
                        },

                        // Error
                        '&.Mui-error': {
                            color: `rgb(var(--color-error))`
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: `rgb(var(--color-error))`
                        },
                    },
                    notchedOutline: {
                        background: 'rgb(var(--color-input-bg-up))',
                        borderColor: `rgb(var(--color-input-border-up))`,
                    }
                },
            },

            MuiCheckbox: {
                defaultProps: {
                    size: 'small',
                    disableFocusRipple: true,
                    disableRipple: true,
                    color: "info"
                    // icon: <FontAwesomeIcon fontSize={'17px'} icon={faSquare} />,
                    // checkedIcon: <FontAwesomeIcon fontSize={'17px'} icon={faSquareCheck} />
                },
                styleOverrides: {
                    root: {
                        color: 'rgb(var(--color-info))',
                        '&.Mui-checked': {
                            color: 'rgb(var(--color-info))'
                        },
                        '&.Mui-disabled': {
                            color: 'rgba(var(--color-info), .9)'
                        }
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginLeft: `-9px`,
                        '&.Mui-disabled .MuiTypography-root': {
                            color: 'rgba(var(--color-info), .9)'
                        }
                    }
                }
            },
            MuiSnackbar: {
                styleOverrides: {
                    root: {
                        '& .MuiPaper-root': {
                            minWidth: '200px'
                        }
                    }
                }
            },

        },


        typography: {
            fontFamily: "proxima-nova",
            fontWeightRegular: 200,
            h1: {
                fontSize: '1.05em',
                fontWeight: 500,
                color: "#444",
                lineHeight: 1.43
            },
            h2: {
                fontSize: '1em',
                fontWeight: 500,
                color: "#444",
                lineHeight: 1.43
            },
            body1: {
                fontSize: '0.95em',
                letterSpacing: '0.3px',
                fontWeight: 300,
                color: "#4d4d4d",
                lineHeight: 1.43
            },
            body2: {
                fontSize: '0.95em',
                letterSpacing: '0.3px',
                fontWeight: 300,
                color: "#9a9a9a",
                lineHeight: 1.43
            },

            // h2: {},
            // h3: {},
            // h4: {},
            // h5: {},
            // h6: {},
            // subtitle1: {},
            // subtitle2: {},
            // body1: {},
            // body2: {},
            // caption: {},
            // button: {},
            // overline: {}
        },
        palette: {
            ...(mode === 'light') ?
                {
                    mode: "light",
                    input: {
                        text: {
                            up: "#4d4d4d",
                            over: "#4d4d4d",
                            focus: "#4d4d4d",
                        },
                        background: {
                            up: "#fafafa",
                            over: "#fafafa",
                            focus: "#fff",
                        },
                        border: {
                            up: "#e6e6e6",
                            over: "#9a9a9a",
                            focus: "#444",
                        }
                    },
                    primary: {
                        main: "#2d8d70",
                        contrastText: "#fff"
                    },
                    secondary: {
                        main: "#444"
                        // main: "#4d4d4d"
                    },
                    info: {
                        main: "#9a9a9a",
                        contrastText: " #fff"
                    },
                    background: {
                        default: '#e9e9e9',
                        paper: '#fff'
                    },
                    backdrop: {
                        background: "rgba(255,255,255,0.3)",
                        color: "#9A9A9A"
                    },
                    text: {
                        primary: "#444",
                        secondary: "#4d4d4d",
                        disabled: "#9a9a9a"
                    },
                    error: {
                        main: "#dc0b0b",
                        contrastText: "#fff"
                    },
                    skill_state: {
                        main: "#9a9a9a",
                        progress: "#fb913b",
                    },

                    /*
                    primary: {
                        // light: string
                        // main: string
                        // dark: string;
                        // contrastText: string
                    },
                    secondary: {},
                    error: {},
                    warning: {},
                    info: {},
                    success: {},
                    // tonalOffset?: PaletteTonalOffset;
                    // contrastThreshold?: number;
                    common:{
                        // black://
                        // white://
                    },
                    // grey?: ColorPartial;
                    // text?: Partial<TypeText>;
                    text:{
                        // primary: string;
                        // secondary: string;
                        // disabled: string;
                    },
                    // divider?: string;
                    action: {
                        // active: string;
                        // hover: string;
                        // hoverOpacity: number;
                        // selected: string;
                        // selectedOpacity: number;
                        // disabled: string;
                        // disabledOpacity: number;
                        // disabledBackground: string;
                        // focus: string;
                        // focusOpacity: number;
                        // activatedOpacity: number;
                    },
                    background:{
                        // default: string;
                        // paper: string;
                    }
                    */
                } : {
                    mode: "dark",
                }
        },
    }
}
